import { Link } from "gatsby";
import React from "react";
import useToggle from "../hooks/useToggle";
import Icon from "./static_images/Icon";

const rightNavIcon = (open) => (open ? "close-rect" : "hamburger-icon");

const railAnimation = (open) => (open ? "slide-in" : "slide-out");

function Nav({ onTheme, themeIcon }) {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <nav className="nav-bar">
        <section className="nav-bar__logo">
          <Link style={{ marginLeft: "-1rem" }} to="/">
            <Icon />
          </Link>
        </section>
        <button
          onClick={onTheme}
          className="nav-bar__theme-toggle"
          aria-label="Toggle dark mode"
        >
          <i className={themeIcon} />
        </button>
        <figure>
          <div>
            <label
              className={rightNavIcon(isOpen)}
              htmlFor="hamburger-icon__checkbox"
            >
              <input
                type="checkbox"
                id="hamburger-icon__checkbox"
                onClick={toggle}
              />
            </label>
          </div>
        </figure>
      </nav>
      <section className={`nav-rail ${railAnimation(isOpen)}`}>
        <div className="nav-rail__content">
          <div className="nav-rail__content-links">
            <Link to="/" hex="#7263DD" activeClassName="active">
              Home
            </Link>
            <Link to="/about/" hex="#7263DD" activeClassName="active">
              About
            </Link>
            <Link to="/contact/" hex="#7263DD" activeClassName="active">
              Contact us
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nav;
