import React, { useLayoutEffect } from 'react';
import { ParallaxProvider, useParallaxController } from 'react-scroll-parallax';
import useToggle from '../hooks/useToggle';
import Footer from './Footer';
import Nav from './Nav';
import '../scss/styles.scss';

const handleThemeIcon = (dark) => (dark ? 'gg-moon' : 'gg-sun');
const handleBackground = (dark) => {
  return dark ? 'dark' : 'light';
};
const getTheme = () => {
  const theme = sessionStorage.getItem('dark');
  if (theme === null || theme === 'true') {
    return true
  }
  return false;
}

const initialDark = () => {
  if (typeof window !== `undefined`) {
    return getTheme();
  }
  return true
}

function DarkLight({ children, ...props }) {
  const [dark, toggleTheme] = useToggle(initialDark());

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      sessionStorage.setItem('dark', dark);
    }
  }, []);

  const handleTheme = () => {
    const newDark = !dark;
    sessionStorage.setItem('dark', JSON.stringify(newDark));
    toggleTheme(newDark);
  }

  return (
    <div background={handleBackground(dark)}>
      <Nav onTheme={handleTheme} themeIcon={handleThemeIcon(dark)} />
      <main {...props}>
        {children}
      </main>
      <Footer />
    </div>
  );
}
function Layout({ children, ...props }) {
  return (
    <ParallaxProvider>
      <DarkLight {...props}>{children}</DarkLight>
    </ParallaxProvider>
  );
}

export default Layout;
