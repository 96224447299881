import React from 'react';

function ContentWrapper({ children, className = '' }) {
  return (
    <div className={`content-wrapper ${className}`}>
      {children}
    </div>
  );
}

export default ContentWrapper;
