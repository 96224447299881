import React from 'react';
import { Link } from "gatsby"
import Icon from './static_images/Icon';

const links = [
  // {
  //   name: 'about',
  //   href: '/about/',
  // },
  // {
  //   name: 'careers',
  //   href: '/careers/',
  // },
  // {
  //   name: 'mission',
  //   href: '/mission/',
  // },
  // {
  //   name: 'merch',
  //   href: '/merch/',
  // },
  // {
  //   name: 'Ai art',
  //   href: '/ai-nft-collection/',
  // },
  // {
  //   name: 'by Turing Lamarr',
  //   href: '/by-turing-lamarr/',
  // },
];
function Footer() {
  // const { site } = useStaticQuery(query)
  return (
    <footer className="footer">
      <div className="footer__logo-section">
        <div className="footer__logo-section-icon">
          <Icon />
        </div>

      </div>
      <div className="footer__link">
        <ul>
          {
            links.map((link) => (
              <li key={link.name}>
                <Link to={link.href} hex="#7263DD" activeClassName="active">
                  {link.name}
                </Link>
              </li>
            ))
          }
        </ul>

        {/* <p className="desktop">
                    As a company we create new innovative engineering solutions to solve a common problem, just how Google, whom is one of many, solved the internet search problem, or Facebook connected people. We also are here to help you create high performant, accessible, and reliable products to solve/improve your own. Anything from end to end business applications, data virtualization, automation tooling, cloud migrations, CVE mitigation, NFTs and anything web.
                </p>
                <h5 className="mobile">
                    Scalable innovative engineering.
                </h5> */}
      </div>
      <div className="footer__contact">
        <span className="cube--contact-us">
          <Link to="/contact/" />
        </span>
      </div>
    </footer>
  );
}
export default Footer;
