import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Icon() {
  return (
    <StaticImage
      src="../../images/Turing_Lamarr.png"
      alt="Turing Lamarr logo, a tardigrade"
      placeholder="blurred"
    />
  );
}

export default Icon;
